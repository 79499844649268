.Logo {
    position: relative;
    width: 260px;
    height: 260px;
    z-index: 2;

    img {
        object-fit: contain;
    }

    &__main {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        filter: drop-shadow(2px 4px 6px black);
    }

  /*   &__blur {
        position: absolute;
        width: 150%;
        height: 150%;
        top: -25%;
        left: -25%;
        z-index: 0;
        filter: blur(7px) grayscale(.3);
        opacity: 0.5;
        display: none;
    } */

}