@font-face {
    font-family: 'Gogono Cocoa Mochi Cyrillic';
    src: url('files/GogonoCocoaMochiCyrillic.eot');
    src: url('files/GogonoCocoaMochiCyrillic.eot?#iefix') format('embedded-opentype'),
        url('files/GogonoCocoaMochiCyrillic.woff2') format('woff2'),
        url('files/GogonoCocoaMochiCyrillic.woff') format('woff'),
        url('files/GogonoCocoaMochiCyrillic.ttf') format('truetype'),
        url('files/GogonoCocoaMochiCyrillic.svg#GogonoCocoaMochiCyrillic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

