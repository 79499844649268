.Text {
    font-family: 'Gogono Cocoa Mochi Cyrillic';
    text-align: center;
    position: relative;
    z-index: 3;

    h1 {
        font-size: 55px;
        margin: 10px auto;
        font-weight: normal;
    }

    h2 {
        font-size: 35px;
        margin: 5px auto;
        font-weight: normal;
    }

    h1, h2 {
        color: transparent;
        background-color: #489148;
        background-image: linear-gradient(90deg, rgba(72,145,72,1) 0%, rgba(129,177,129,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        filter: drop-shadow(2px 4px 6px black);
    }
}