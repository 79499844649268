.Main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    background-color: #202a44;
    background-image: linear-gradient(90deg, rgba(83,91,121,1) 0%, rgba(32,42,68,1) 100%);
    position: relative;
}