.Paws {
    position: absolute;
    height: 125px;
    transform-origin: left;

    &__paw {
        position: absolute;
        width: 40px;
        opacity: 1;
        animation-name: opacityChange;
		animation-timing-function: linear;
		animation-direction: normal;
		animation-fill-mode: both;
    }
}

  @keyframes opacityChange {
    0% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    33% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
