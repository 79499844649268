#lucky_calc {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
   /*  min-width: 690px; */

    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
